<template>
  <div>
    <div v-if="error" class="center-center">
      <div>
        <p>Single-Sign on failed. Please contact support for access.</p>
        <div>
          <PrimeButton to="/login" block>Return to Login</PrimeButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authApi from "@/services/AuthApi";

export default {
  name: "LoginCallback",
  data() {
    return {
      error: false
    };
  },
  async created() {
    try {
      const data = await authApi.handleCallback();

      if (!data) {
        this.$router.push({ name: "Login" });
        return;
      }

      if (data.access_token) {
        this.setAuthTokens(data);
        if (window.localStorage.getItem("login.goto")) {
          this.$route.query.goto = window.localStorage.getItem("login.goto");
          window.localStorage.removeItem("login.goto");
        }
        const goto = this.$route.query.goto ? { goto: this.$route.query.goto } : {};
        this.$router.push({ path: "/", query: goto });
      } else {
        khanSolo.error("Authorization token not included in the response.");
        this.error = true;
      }
    } catch (error) {
      khanSolo.error(error);
      this.error = true;
    }
  }
};
</script>
